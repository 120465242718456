import React, { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useQuery } from '../../hooks';
import ScanResults from '../../component/scan-results/ScanResults';
import FetchResult from '../../component/fetchResult/FetchResult';
import API from '../../utils/httpService';
import SubscriptionModal from '../../component/subscriptionModal/SubscriptionModal';
import { useLocation } from 'react-router-dom';
import Heart from '../../assets/images/svg/heart.svg';
import Info from '../../assets/images/svg/info.svg';
import ResultErrorModal from '../../component/resultErrorModal/ResultErrorModal';
import ScanResultModal from '../../component/scanResultModal/ScanResultModal';
import Cookies from 'js-cookie';
import { isAndroid, isIOS } from 'react-device-detect';


export const Results: FC = () => {
  const query = useQuery();
  const [resultsData, setResultsData] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [fetchResultShow, setFetchResultShow] = useState<boolean>(true);
  const [showScanResultData, setShowScanResultData] = useState<any>();
  const [vitalsInfoModalData, setVitalsInfoModalData] = useState<any>();
  const [tryNowData, setTryNowData] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [openSubscribeModal, setOpenSubscribeModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [totalSeconds, setTotalSeconds] = useState<string>("");
  const [countDown, setCountDown] = useState<string>('00:00');
  const [paddingTop, setPaddingTop] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [scanResult, setScanResult] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const handlesetScanResultClose = () => setScanResult(false);

  const location = useLocation();
  const myParam = new URLSearchParams(location.search).get('error');
  const handleResultCloseModal = () => setOpenErrorModal(false);

  const handleUpdateCloseModal = () => {
    setOpenSubscribeModal(false);
  }

  const handleScanResultOpen = (vital: any) => {
    setScanResult(true);
    setVitalsInfoModalData(vital);
  }

  useEffect(() => {
    if (myParam !== null) {
      setOpenErrorModal(true)
    }
  }, [])

  const handleSelectedItem = (hash: any) => {
    setSelectedItem(hash)
    setPaddingTop(60);
  }

  useEffect(() => {
    if (!totalSeconds || !/^\d{2}:\d{2}$/.test(totalSeconds)) {
      // console.error('Invalid totalSeconds format');
      return;
    }

    const [hours, minutes] = totalSeconds.split(':').map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
      // console.error('Invalid totalSeconds values');
      return;
    }

    let timer = (hours * 3600) + (minutes * 60);

    const interval = setInterval(() => {
      const currentHours = Math.floor(timer / 3600);
      const currentMinutes = Math.floor((timer % 3600) / 60);

      const formattedHours = currentHours < 10 ? '0' + currentHours : currentHours.toString();
      const formattedMinutes = currentMinutes < 10 ? '0' + currentMinutes : currentMinutes.toString();

      setCountDown(`${formattedHours}:${formattedMinutes}`);

      if (--timer < 0) {
        clearInterval(interval);
        setCountDown('00:00');
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [totalSeconds]);

  useEffect(() => {
    const results = query.get('results');
    setFetchResultShow(true)
    try {
      if (!results || results === '') {
        return;
      }

      const resultsBuffer = Buffer.from(results, 'base64');
      const resultsObj = JSON.parse(resultsBuffer.toString());

      setResultsData(resultsObj);

    } catch (e) {
      console.log(e);
    }
  }, [JSON.stringify(resultsData)]);

  const healthScanApi = async () => {
    const payload = {
      journey_type: tryNowData?.is_journey == 0 ? 1 : 0,
      id: Cookies.get("resultDataUserId") && (Cookies.get("resultDataUserId") !== undefined && Cookies.get("resultDataUserId") !== "undefined") ? Cookies.get("resultDataUserId") : '',
      results: JSON.stringify(resultsData),
      blood_pressure: `${resultsData?.BP_SYSTOLIC}/${resultsData?.BP_DIASTOLIC}` || null,
      stress_level: `${resultsData?.MSI}` || null,
      respiratory_rate: `${resultsData?.BR_BPM}` || null,
      heart_rate: `${resultsData?.HR_BPM}` || null,
      bmi: `${resultsData?.BMI_CALC}` || null,
      irregular_heartbeat: `${resultsData?.IHB_COUNT}` || null,
      anxiety_depression: `${resultsData?.SURVEY_ANXIETY_MODERATE}` || null,
      cvd_risk: `${resultsData?.BP_CVD}` || null,
      stroke_risk: `${resultsData?.BP_STROKE}` || null,
      heart_attack_risk: `${resultsData?.BP_HEART_ATTACK}` || null,
      hbA1C: `${resultsData?.HBA1C_RISK_PROB}` || null,
      blood_glucose: `${resultsData?.MFBG_RISK_PROB}` || null,
      hrv_pnn50: null,
      hrv_hd1_hd2: null,
      cardiac_workload: `${resultsData?.BP_RPP}` || null,
      waist_to_height: `${resultsData?.WAIST_TO_HEIGHT}` || null,
      body_shape: `${resultsData?.ABSI}` || null,
      facial_skin_age: `${resultsData?.AGE}` || null,
      t2_diabetes: `${resultsData?.DBT_RISK_PROB}` || null,
      hypertension: `${resultsData?.HPT_RISK_PROB}` || null,
      hypertriglyceridemia: `${resultsData?.TG_RISK_PROB}` || null,
      fatty_liver_disease: `${resultsData?.FLD_RISK_PROB}` || null,
      sdnn: `${resultsData?.HRV_SDNN}` || null,
      vascular_capacity: `${resultsData?.BP_TAU}` || null,
      hyper_cholesterolemia_risk: `${resultsData?.HDLTC_RISK_PROB}` || null,
      estimated_height: `${resultsData?.HEIGHT}` || null,
      general_wellness_score: `${resultsData?.HEALTH_SCORE}` || null,
      mental_score: `${resultsData?.MENTAL_SCORE}` || null,
      overall_metabolic_health_risk: `${resultsData?.OVERALL_METABOLIC_RISK_PROB}` || null,
      physical_score: `${resultsData?.PHYSICAL_SCORE}` || null,
      physiological_score: `${resultsData?.PHYSIO_SCORE}` || null,
      risks_score: `${resultsData?.RISKS_SCORE}` || null,
      signal_noise_ratio: `${resultsData?.SNR}` || null,
      moderate_depression: `${resultsData?.SURVEY_DEPRESSION_MODERATE}` || null,
      vitals_score: `${resultsData?.VITAL_SCORE}` || null,
      waist_circumference: `${resultsData?.WAIST_CIRCUM}` || null,
      estimated_weight: `${resultsData?.WEIGHT}` || null,
    }
    try {
      const response = await API.post('/new-health-scan', payload);
      setShowScanResultData(response?.data);
      if (response?.data?.id) {
        Cookies.set('resultDataUserId', response?.data?.id)
      }
      if (response?.status) {
        setFetchResultShow(false)
        Cookies.remove('userFirstTime');
        Cookies.remove('updateProfileModalShow');
      } else {
        setFetchResultShow(false)
      }
    } catch (error) {
      console.log(error, "errorrr")
    }
  }

  useEffect(() => {
    if (resultsData && tryNowData) {
      healthScanApi();
    }
  }, [JSON.stringify(resultsData), tryNowData])

  const tryNowJourney = async () => {
    const res = await API.get(`/try-now`);
    if (res?.status) {
      setTryNowData(res?.data);
      setTotalSeconds(res?.data?.remaining_time);
    }
    else {
      setTryNowData(true);
    }
  }


  const getUserData = async () => {
    const res = await API.get(`/user`);
    if (res?.status) {
      setUserData(res?.data?.user);
    }
  }

  useEffect(() => {
    tryNowJourney();
    getUserData();
  }, [])

  const redirectToScan = () => {
    window.location.href = '/?fisjourney=startscan';
  }

  const redirectToScanTryNow = () => {
    window.location.href = '/?isjourney=trynow';
  }

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    if (scrollPosition < 30) {
      setPaddingTop(0);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  useEffect(() => {
    const handleScroll = () => {
      // Set isScrolled to true when the user scrolls past a certain point, for example, 100px
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const redirectHandler = () => {
    if (isAndroid == true) {
      window.location.href = "https://play.google.com/store/apps/details?id=pk.merisehat.app&pli=1";

    } else if (isIOS == true) {
      window.location.href = "https://apps.apple.com/pk/app/meri-sehat/id1643174046";
    }
  }

  return (
    <div className='d-md-none'>
      {userData?.is_corporate == false && isScrolled ? (
        <>
          <div className='remainder_all_vitals'>
            <div className='icon_lock'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_2244_25131)">
                  <path d="M17.9631 8.48148H17.0372V6.62963C17.0372 4.07407 14.9631 2 12.4075 2C9.85197 2 7.7779 4.07407 7.7779 6.62963V8.48148H6.85197C5.83346 8.48148 5.00012 9.31482 5.00012 10.3333V19.5926C5.00012 20.6111 5.83346 21.4444 6.85197 21.4444H17.9631C18.9816 21.4444 19.8149 20.6111 19.8149 19.5926V10.3333C19.8149 9.31482 18.9816 8.48148 17.9631 8.48148ZM12.4075 16.8148C11.389 16.8148 10.5557 15.9815 10.5557 14.963C10.5557 13.9444 11.389 13.1111 12.4075 13.1111C13.426 13.1111 14.2594 13.9444 14.2594 14.963C14.2594 15.9815 13.426 16.8148 12.4075 16.8148ZM15.2779 8.48148H9.53716V6.62963C9.53716 5.0463 10.8242 3.75926 12.4075 3.75926C13.9909 3.75926 15.2779 5.0463 15.2779 6.62963V8.48148Z" fill="#19B3B5" />
                </g>
                <defs>
                  <clipPath id="clip0_2244_25131">
                    <rect width="22.2222" height="22.2222" fill="white" transform="translate(0.888794 0.888672)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p>To view all remaining vitals of your results download our app now</p>
            <button className='downloadNow' onClick={() => redirectHandler()}>DOWNLOAD NOW</button>
          </div>
        </>
      ) : null}

      {!openErrorModal && fetchResultShow && (
        <FetchResult />
      )}
      {!openErrorModal && !fetchResultShow && (
        <>
          {/* result */}
          <div className={`scanTabs`}>
            <ScanResults />
            <nav id="navbar-example2" className="navbar navbar-light px-0 scan-result sticky-top">
              <ul className="nav nav-pills">
                {showScanResultData?.healthscan?.length > 0 && showScanResultData?.healthscan?.map((item: any, index: any) => (
                  <li key={index} className="nav-item">
                    <a className={selectedItem == index ? "nav-link actived" : "nav-link"} onClick={() => handleSelectedItem(index)} href={`#${index}`}>{item?.title}</a>
                  </li>
                ))}
              </ul>
            </nav>
            <div className={`bg-col ${userData?.is_corporate == false && isScrolled ? 'hk_blur' : ''}`}>
              <div className='boxed'>
                <p>Results shared are not for medical use. For medical opinions, consult a healthcare professional.</p>
                <a href="https://merisehat.pk/" target='blank'>www.merisehat.pk</a>
              </div>
              <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" className="scrollspy-example" tabIndex={0}>
                {userData?.is_corporate == false ? (
                  <>
                    {showScanResultData?.healthscan?.length > 0 && showScanResultData?.healthscan?.map((item: any, index: any) => {
                      return (
                        <>
                          <div className='main_single_wrapper' id={`${index}`} style={{ paddingTop: `${selectedItem == index ? paddingTop : '0'}px` }}>
                            <div className='d-flex align-items-center mb-4'>
                              <img src={item?.icon} alt="" />
                              <h5 className='mb-0'> {item?.title}</h5>
                            </div>
                            {item?.result?.length > 0 && item?.result?.map((vital: any) => {
                              return (
                                <>
                                  <div className='box-bg' onClick={(e) => handleScanResultOpen(vital)}>
                                    <Grid item xs={8}>
                                      <Typography variant="button"><span className='title'>{vital?.title}</span>
                                        <div className='info_icon'>
                                          <img src={Info} alt="" onClick={(e) => handleScanResultOpen(vital)} />
                                        </div>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography variant="body1">{index < 1 ? vital?.value : '-'} <span>{index < 1 ? vital?.unit : ''}</span></Typography>
                                      <h6 className='tag__s' style={{ color: vital?.color }}>{vital?.tag}</h6>
                                    </Grid>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </>
                      )
                    })}
                  </>
                ) : (
                  <>
                    {showScanResultData?.healthscan?.length > 0 && showScanResultData?.healthscan?.map((item: any, index: any) => {
                      return (
                        <>
                          <div className='main_single_wrapper' id={`${index}`} style={{ paddingTop: `${selectedItem == index ? paddingTop : '0'}px` }}>
                            <div className='d-flex align-items-center mb-4'>
                              <img src={item?.icon} alt="" />
                              <h5 className='mb-0'> {item?.title}</h5>
                            </div>
                            {item?.result?.length > 0 && item?.result?.map((vital: any) => {
                              return (
                                <>
                                  <div className='box-bg' onClick={(e) => handleScanResultOpen(vital)}>
                                    <Grid item xs={8}>
                                      <Typography variant="button"><span className='title'>{vital?.title}</span>
                                        <div className='info_icon'>
                                          <img src={Info} alt="" onClick={(e) => handleScanResultOpen(vital)} />
                                        </div>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography variant="body1">{vital?.value} <span>{vital?.unit}</span></Typography>
                                      <h6 className='tag__s' style={{ color: vital?.color }}>{vital?.tag}</h6>
                                    </Grid>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </>
                      )
                    })}
                  </>
                )}
              </div>
            </div>
            {tryNowData?.is_journey == 0 ? (
              <>
                <div className='fullWidthButton whenTryJourneyIsAvail'>
                  <div className='doctor-button'>
                    <button onClick={(e) => redirectToScanTryNow()} className='btn scan'><span className='text_button'>Scan Again</span><span className='ticker'>{tryNowData?.free_health_scan - tryNowData?.healthCount} of 3</span></button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='doctor-button'>
                  <a href='https://merisehat.pk/doctor-now' target='blank' className='btn call'>Call a Doctor</a>
                  <button onClick={(e) => userData?.subscription_recent == null ? setOpenSubscribeModal(true) : redirectToScan()} className='btn scan'>Scan Again</button>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <SubscriptionModal handleUpdateCloseModal={handleUpdateCloseModal} openSubscribeModal={openSubscribeModal} />
      <ResultErrorModal tryNowData={tryNowData} userData={userData} handleResultCloseModal={handleResultCloseModal} openErrorModal={openErrorModal} />
      <ScanResultModal setScanResult={setScanResult} setOpenSubscribeModal={setOpenSubscribeModal} redirectToScan={redirectToScan} redirectToScanTryNow={redirectToScanTryNow} tryNowData={tryNowData} userData={userData} handleUpdateCloseModal={handleUpdateCloseModal} openSubscribeModal={openSubscribeModal} vitalsInfoModalData={vitalsInfoModalData} handlesetScanResultClose={handlesetScanResultClose} scanResult={scanResult} />
    </div>
  );
};
